import { useEffect, useState } from "react";
import { Container, Modal, Row } from "react-bootstrap";
import { fetchSchedule, IScheduleData } from "../axios";
import InfoCard from "./InfoCard";
import Day from "./Day";
import Loader from "./Loader";
import gala_f from "../images/schedule/gala_first_half.png";
import gala_s from "../images/schedule/gala_second_half.png";

interface ScheduleProps {
  giscode: string;
}

const Schedule = ({ giscode }: ScheduleProps) => {
  const [info, setInfo]: [
    IScheduleData | undefined,
    (info: IScheduleData | undefined) => void
  ] = useState();

  const [error, setError] = useState<boolean>(false);

  const [rand] = useState<number>(Math.floor(Math.random() * 6));

  const [show, setShow] = useState<boolean>(true);

  const fetchInfo = async () => {
    try {
      const data = await fetchSchedule(giscode);
      setInfo(data);
      setError(false);
    } catch (error) {
      if (error instanceof Error) setError(true);
    }
  };

  useEffect(() => {
    fetchInfo();
  });

  return info === undefined ? (
    <Loader error={error} />
  ) : (
    <Container className="tw-font-gis tw-pt-[2%] tw-w-[90%]">
      <Row>
        <h2 className="tw-font-bold tw-px-2 tw-py-2">
          Hello {info.full_name}
        </h2>
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <InfoCard
          giscode={giscode}
          full_name={info.full_name}
          dietary_requirement={info.dietary_requirement}
          grouping={info.grouping}
          iden={info.iden}
        />
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <Day
          day={1}
          events={[
            {
              time: "9:00 - 10:30",
              name: "Opening Ceremony",
              location: info.day1_opening,
            },
            {
              time: "10:40 - 12:20",
              name: "Panel Discussion - Financial Technology",
              location: info.day1_panel_morning,
            },
            {
              time: "12:20 - 13:10",
              name: "Lunch Break",
              location: info.day1_lunch,
            },
            {
              time: "13:30 - 15:10",
              name: "Panel Discussion - Digital Transformation",
              location: info.day1_panel_af_first,
            },
            {
              time: "15:30 - 17:10",
              name: "Technology in Educational Inequality",
              location: info.day1_panel_af_second,
            },
            ...(info.iden !== "Observer"
              ? [{
                time: "17:40 - 21:00",
                name: "Night Tour",
                location: info.day1_nighttour,
                }]
              : []),
          ]}
        />
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <Day
          day={2}
          events={[
            ...(info.iden !== "Observer"
              ? [{
                time: "9:00 - 12:00",
                name: "Mentor Discussion",
                location: info.day2_mentor,
                }]
              : []),
            ...(info.iden !== "Observer"
              ? [{
                time: "12:00 - 13:00",
                name: "Lunch Break",
                location: info.day2_lunch,
                }]
              : []),
            {
              time: "14:00 - 15:30",
              name: "World Café",
              location: info.day2_worldcafe,
              content: {
                "Round 1": "not given",
                "Round 2": "not given",
                "Round 3": "not given",
              },
            },
            {
              time: "15:30 - 17:30",
              name: "Cultural Festival",
              location: info.day2_culture,
            },
          ]}
        />
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <Day
          day={3}
          events={[
            ...(info.iden !== "Observer"
              ? [
                {
                time: "9:00 - 12:00",
                name: "Critical Sprout - 1",
                location: info.day3_critical1,
              },
              {
                time: "12:00 - 13:00",
                name: "Lunch Break",
                location: info.day3_lunch,
              },
              {
                time: "13:00 - 15:00",
                name: "Critical Sprout - 2",
                location: info.day3_critical2,
              },
              {
                time: "15:00 - 17:00",
                name: "Mentor Discussion",
                location: info.day3_mentor,
              },
            ]
              : []),
          ]}
        />
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <Day
          day={4}
          events={[
            ...(info.iden !== "Observer"
              ? [{
                time: "9:20 - 11:30",
                name: "Mentor Discussion",
                location: info.day4_mentor,
              },
              {
                time: "11:30 - 12:20",
                name: "Lunch Break",
                location: info.day4_lunch,
              },
            ]
              : []),
            
            {
              time: "13:30 - 16:30",
              name: "Leaders Dialogue",
              //location :`W Hotel 8F Mega Room, Table ${info.day4_dialogue_location}`,
              location: info.day4_dialogue_location,
              content: {
                "Round 1": info.round1,
                "Round 2": info.round2,
                "Round 3": info.round3,
              },
            },
            {
              time: "16:30 - 17:00",
              name: "GALA - First Half",
              location: "Grand Hyatt Taipei - Ballroom Foyer",
              imageUrl: gala_f,
            },
            {
              time: "17:20 - 17:50",
              name: "GALA - Second Half",
              location: "Grand Hyatt Taipei - Ballroom Foyer",
              imageUrl: gala_s,
            },
            ...(info.iden !== "Observer"
              ? [{
                time: "18:00 - 21:00",
                name: "Banquet",
                //location: `W Hotel 8F Mega Room, Table ${info.banquet}`,
                location: info.banquet,
              }]
              : []),
            
          ]}
        />
      </Row>
      <Row className="tw-px-2 tw-py-2">
        <Day
          day={5}
          events={[
            {
              time: "8:30 - 12:00",
              name: "IP Presentation",
              location: info.day5_presentation,
            },
            {
              time: "12:00 - 13:00",
              name: "Lunch Break",
              location: info.day5_lunch,
            },
            {
              time: "13:00 - 13:40",
              name: "IP Exhibition",
              location: info.day5_exhibition,
            }, 
            {
              time: "13:50 - 14:40",
              name: "IP Awarding",
              location:
                info.day5_awarding,
            },
            {
              time: "14:40 - 16:10",
              name: "Closing Ceremony",
              location:
                info.day5_closing,
            },
            ...(info.iden !== "Observer"
              ? [ {
                time: "18:00 - 21:00",
                name: "After Party",
                location: info.day5_party,
              }]
              : []),
          ]}
        />
      </Row>
      
    </Container>
  );
};

export default Schedule;
