import axios, { AxiosInstance } from "axios";

const instance: AxiosInstance = axios.create({
  baseURL: "https://be.admin.gis-taiwan.ntu.edu.tw",
});
/*https://be.admin.gis-taiwan.ntu.edu.tw*/
interface IScheduleData {
  full_name: string;
  grouping: string;
  iden: string;
  dietary_requirement: string;
  day1_opening: string;
  day1_panel_morning: string;
  day1_lunch: string;
  day1_panel_af_first: string;
  day1_panel_af_second: string;
  day1_nighttour: string;
  day2_mentor: string;
  day2_lunch: string;
  day2_worldcafe: string;
  day2_culture: string;
  day3_critical1: string;
  day3_lunch: string;
  day3_critical2: string;
  day3_mentor: string;
  day4_mentor: string;
  day4_lunch: string;
  day4_dialogue_location: string;
  round1: string;
  round2: string;
  round3: string;
  banquet: string;
  day5_presentation: string;
  day5_lunch: string;
  day5_exhibition: string;
  day5_awarding: string;
  day5_closing: string;
  day5_party: string;
}
/*


CREATE TABLE schedule (
   id SERIAL PRIMARY KEY,
   gis_code VARCHAR(255) NOT NULL,
   full_name TEXT NOT NULL,
   grouping TEXT NOT NULL,
   iden TEXT NOT NULL,
   dietary_requirement TEXT NOT NULL,
   day1_opening TEXT NOT NULL,
   day1_panel_morning TEXT NOT NULL,
   day1_lunch TEXT NOT NULL,
   day1_panel_af_first TEXT NOT NULL,
   day1_panel_af_second TEXT NOT NULL,
   day1_nighttour TEXT NOT NULL,
   day2_mentor TEXT NOT NULL,
   day2_lunch TEXT NOT NULL,
   day2_worldcafe TEXT NOT NULL,
   day2_culture TEXT NOT NULL,
   day3_critical1 TEXT NOT NULL,
   day3_lunch TEXT NOT NULL,
   day3_critical2 TEXT NOT NULL,
   day3_mentor TEXT NOT NULL,
   day4_mentor TEXT NOT NULL,
   day4_lunch TEXT NOT NULL,
   day4_dialogue_location TEXT NOT NULL,
   round1 TEXT NOT NULL,
   round2 TEXT NOT NULL,
   round3 TEXT NOT NULL,
   banquet TEXT NOT NULL,
   day5_presentation TEXT NOT NULL,
   day5_lunch TEXT NOT NULL,
   day5_exhibition TEXT NOT NULL,
   day5_awarding TEXT NOT NULL,
   day5_closing TEXT NOT NULL,
   day5_party TEXT NOT NULL
);
CREATE TABLE schedule (
    id SERIAL PRIMARY KEY,
    gis_code VARCHAR(255) NOT NULL,
    full_name TEXT NOT NULL,
    grouping TEXT NOT NULL,
    dietary_requirement TEXT NOT NULL,
    day1_lunch TEXT NOT NULL,
    day2_lunch TEXT NOT NULL,
    day2_mentor TEXT NOT NULL,
    day3_critical TEXT NOT NULL,
    day3_lunch TEXT NOT NULL,
    day3_group TEXT NOT NULL,
    day4_lunch TEXT NOT NULL,
    day5_presentation TEXT NOT NULL,
    day5_exhibition TEXT NOT NULL,
    day5_lunch TEXT NOT NULL,
    round1 TEXT NOT NULL,
    round2 TEXT NOT NULL,
    round3 TEXT NOT NULL,
    banquet TEXT NOT NULL
);


*/
const verifyCode = async (giscode: string): Promise<boolean> => {
  try {
    await instance.get(`/verify/${giscode}`);
    return true;
  } catch (error) {
    return false;
  }
};

const fetchSchedule = async (giscode: string): Promise<IScheduleData> => {
  const response = await instance.get(`/schedule/${giscode}`);
  return response.data;
};

export { verifyCode, fetchSchedule, type IScheduleData };
